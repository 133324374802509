import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { GatsbyImage , getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import useWindowSize from '../hooks/useWindowSize';

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const image1 = getImage('/img/competitive-intelligence.jpg')
  const { width } = useWindowSize();
  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div style={{display: 'flex', flexDirection: (width > 768 ? 'row': 'column-reverse'), gap: '5%'}}>
                <div style={{width: (width > 768 ? "55%": "100%"), padding: (width > 768 ? "0%": "5% 0%"), display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <h1 style={{color: '#E65E27', textAlign: (width>768 ? 'left': 'center'), lineHeight: '1.5', fontSize: (width > 768 ? "2em": "1.4em")}}>
                    BTK Inhibitors: The Next Frontier in Multiple Sclerosis (MS) Treatment?
                    </h1>
                    <p style={{textAlign: (width>768 ? 'left': 'center'), width: (width > 768 ? "100%": "80%")}}>By Avisha Jain and Nitin Kumar | Wednesday 18, Sep 2024</p>
                </div>
                <div style={{width: (width> 768 ? "45%": "100%"), display: "flex", justifyContent: "center"}}>
                {image1 ? (
                    <GatsbyImage image={image1} alt='Loading...' className='blog-image'/>
                    ) : (
                    <img src='/img/BTK_Article_Image.png' alt='Loading...' className='blog-image' style={{height: '250px'}}/>
                )}
                </div>
            </div>
            <div className="sub-section">
                <h4>Introduction</h4>
                <p>Multiple sclerosis (MS) is a complex autoimmune disorder characterized by the progressive destruction of myelin in the central nervous system. According to WHO, it is estimated that over 1.8 million people have MS worldwide. Bruton's tyrosine kinase (BTK) has emerged as a critical player in the pathogenesis of MS due to its role in immune cell activation and inflammation. This article explores the role of BTK in MS and provides an overview of the BTK inhibitors currently under development, including BIIB091, Evobrutinib, Fenebrutinib, Orelabrutinib, Remibrutinib, and Tolebrutinib.<sup><a style={{textDecoration: 'underline'}} href="https://www.who.int/news-room/fact-sheets/detail/multiple-sclerosis" target="_blank">1, 22</a></sup></p>
            </div>
            <div className="sub-section">
                <h4>Bruton's Tyrosine Kinase: A Key Immune Regulator</h4>
                <p>BTK is a cytoplasmic tyrosine kinase essential for B-cell maturation and function. It is also involved in the signalling of other immune cells, such as mast cells, macrophages, and microglia. Given its role in regulating immune responses, BTK is a significant target for therapies aimed at autoimmune diseases like MS.</p>
            </div>
            <div className="sub-section">
                <h4>BTK and Multiple Sclerosis: The Connection<sup><a style={{textDecoration: 'underline', fontWeight: '400', fontSize: '75%'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC11025433/" target="_blank">2</a></sup></h4>
                <div>
                    <ol>
                    <li style={{fontWeight: 'bold', padding: '1% 0%'}}>
                        <h4>Immune Cell Activation</h4>
                        <ul style={{listStyleType: 'circle'}}>
                            <li>
                                <p style={{fontWeight: 'normal'}}>
                                In MS, immune cell activation, particularly B cells and microglia, drives myelin destruction. BTK facilitates B-cell receptor signalling, which is crucial for B-cell activation and antibody production. Additionally, BTK's involvement in microglial activation links it to neuroinflammation in MS.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li style={{fontWeight: 'bold', padding: '1% 0%'}}>
                        <h4>B-Cell Contribution</h4>
                        <ul style={{listStyleType: 'circle'}}>
                            <li>
                                <p style={{fontWeight: 'normal'}}>
                                B cells contribute to MS through antibody production and cytokine release. Targeting BTK can modulate B-cell activity, potentially reducing the autoimmune attack on myelin.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li style={{fontWeight: 'bold', padding: '1% 0%'}}>
                        <h4>Microglial Activation</h4>
                        <ul style={{listStyleType: 'circle'}}>
                            <li>
                                <p style={{fontWeight: 'normal'}}>
                                BTK is involved in microglial activation, which contributes to neuroinflammation and damage in MS. Inhibiting BTK could help reduce this neuroinflammatory process.
                                </p>
                            </li>
                        </ul>
                    </li>
                    </ol>
                </div>
            </div>
            <div className="sub-section">
                <h4>BTK Inhibitors: Current Developments</h4>
                <p>The development of BTK inhibitors represents a promising strategy for MS treatment. Several BTK inhibitors are under investigation, each with unique properties and potential benefits. The table below provides a comparative overview of these inhibitors based on recent clinical trial data:</p>
                <div style={{overflowX: (width > 768 ? 'unset': 'scroll')}}>
                    <table className="references-table">
                        <tr>
                            <th style={{width: '14.28%'}}></th>
                            <th style={{width: '14.28%', color: 'white', textAlign: 'center'}}>Evobrutinib (75 mg BID)</th>
                            <th style={{width: '14.28%', color: 'white', textAlign: 'center'}}>Tolebrutinib (60 mg QD)</th>
                            <th style={{width: '14.28%', color: 'white', textAlign: 'center'}}>Fenebrutinib (200 mg BID)</th>
                            <th style={{width: '14.28%', color: 'white', textAlign: 'center'}}>Remibrutinib</th>
                            <th style={{width: '14.28%', color: 'white', textAlign: 'center'}}>Orelabrutinib</th>
                            <th style={{width: '14.28%', color: 'white', textAlign: 'center'}}>BIIB091</th>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td style={{color: 'black', fontWeight: '600', fontSize: '18px'}}>Company</td>
                            <td>Merck KGaA</td>
                            <td>Sanofi</td>
                            <td>Roche</td>
                            <td>Novartis</td>
                            <td>Innocare</td>
                            <td>Biogen</td>
                        </tr>
                        <tr>
                            <td style={{color: 'black', fontWeight: '600', fontSize: '18px'}}>Development Phase</td>
                            <td>P3 (Discontinued)</td>
                            <td>P3</td>
                            <td>P3</td>
                            <td>P3</td>
                            <td>P2</td>
                            <td>P2</td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td style={{color: 'black', fontWeight: '600', fontSize: '18px'}}>BTK Binding<sup><a style={{textDecoration: 'underline', fontWeight: '400', fontSize: '75%'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9315179/" target="_blank">3</a></sup></td>
                            <td>Covalent, Irreversible</td>
                            <td >Covalent, Irreversible</td>
                            <td>Non-covalent, Reversible</td>
                            <td>Covalent, Irreversible</td>
                            <td>Covalent, Irreversible</td>
                            <td>Non-covalent, Reversible</td>
                        </tr>
                        <tr>
                            <td style={{color: 'black', fontWeight: '600', fontSize: '18px'}}>Half Life (h)<sup><a style={{textDecoration: 'underline', fontWeight: '400', fontSize: '75%'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC11025433/" target="_blank">4</a></sup></td>
                            <td>2</td>
                            <td>2</td>
                            <td>4.2-9.9</td>
                            <td>1-2</td>
                            <td>4</td>
                            <td>0.66</td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td style={{color: 'black', fontWeight: '600', fontSize: '18px'}}>Relative
                                reductions in
                                Gd+
                                lesions<sup>5,6,7</sup>
                            </td>
                            <td>--</td>
                            <td>Ph2 @12 wks - 89%</td>
                            <td>Ph2 @12 wks - 90%</td>
                            <td>--</td>
                            <td>Ph2 @12 wks - 92.5%</td>
                            <td>--</td>
                        </tr>
                        <tr>
                            <td style={{color: 'black', fontWeight: '600', fontSize: '18px'}}>Annualized
                                Relapse
                                Rate<sup>8,9,10,11</sup>
                            </td>
                            <td>Ph2 @48 wks - 0.11</td>
                            <td>Ph2 @48 wks - 0.17</td>
                            <td>Ph2 @48 wks - 0.04</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                        </tr>
                    </table>
                </div>
                <p>Note: “--" Relevant data for comparison were not available in public domain</p>
            </div>
            <div className="sub-section">
                <h4>Annualized Relapse Rate (ARR) for BTK Inhibitors in MS</h4>
                <p>Below is a chart showing the Annualized Relapse Rate (ARR) for various BTK inhibitors based on recent clinical trial data:</p>
                <div>
                {image1 ? (
                    <GatsbyImage image={image1} alt='Loading...' className='blog-image'/>
                    ) : (
                    <img src='/img/Article_Image_2.png' alt='Loading...' className='blog-image'/>
                )}
                </div>
                <p>Note: Ph2/Ph3 assets are considered in the analysis</p>
            </div>
            <div className="sub-section">
                <h4>Relative reduction in GD+ Lesion for BTK Inhibitors in MS:</h4>
                <p>Below is a chart showing the Relative reduction in GD+ lesion for various BTK inhibitors based on recent clinical trial data</p>
                <div>
                {image1 ? (
                    <GatsbyImage image={image1} alt='Loading...' className='blog-image'/>
                    ) : (
                    <img src='/img/Article_Image_3.png' alt='Loading...' className='blog-image'/>
                )}
                </div>
                <p>Note: Ph2/Ph3 assets are considered in the analysis</p>
            </div>
            <div className="sub-section">
                <h4>Development Updates</h4>
                <ul>
                    <li style={{padding: '1% 0%'}}>
                        <strong style={{paddingRight: '1%'}}>BIIB091:</strong> Biogen's BIIB091 is currently in Phase 2 trials and is showing promise in reducing relapse rates in relapsing MS. The upcoming milestone for BIIB091 includes the completion of Phase 2 trials, which will further assess its efficacy and safety in a larger cohort. Biogen is also exploring the drug's potential benefits in combination therapies.<sup><a style={{textDecoration: 'underline'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8204096/#:~:text=In%20vitro%2C%20BIIB091%20potently%20inhibited%20BTK%E2%80%90dependent%20proximal%20signaling,efficacy%20of%20B%20cell%E2%80%90targeted%20therapeutics%20in%20multiple%20sclerosis." target="_blank">12,13,14</a></sup>
                    </li>
                    <li style={{padding: '1% 0%'}}>
                        <strong style={{paddingRight: '1%'}}>Evobrutinib:</strong> Merck KGaA's Evobrutinib, which is in Phase 3 development, had encountered a significant setback on 6th December 2023. Despite earlier promising results, recent Phase 3 trials have failed to meet their primary endpoints for reducing relapse rates in relapsing MS. This challenge underscores the complexities of drug development and the need for continued innovation in treatment approaches.<sup><a style={{textDecoration: 'underline'}} href="https://www.merckgroup.com/en/news/evobrutinib-phase-lll.html" target="_blank">15</a></sup>
                    </li>
                    <li style={{padding: '1% 0%'}}>
                        <strong style={{paddingRight: '1%'}}>Fenebrutinib:</strong> Fenebrutinib, developed by Genentech/Roche, is advancing through Phase 3 trials. Phase 2 results indicate that Fenebrutinib has met primary endpoints in reducing relapse rates and lesion activity. The next significant milestone will be the final data analysis from ongoing trials, which will help determine its place in the MS treatment landscape and possible filing for regulatory approval. New enrolment for the Phase III trial in the U.S. is paused; enrolment in countries outside of the U.S. continues. Fenebrutinib is currently being investigated in two phase 3 trials, the Phase 3 program includes two identical trials in RMS (FENhance I & II) with an active teriflunomide comparator with results due in 2025 and 2028. If the results are positive, Fenebrutinib will be submitted for regulatory approval.<sup><a style={{textDecoration: 'underline'}} href="https://www.gene.com/media/statements/ps_113023" target="_blank">16</a></sup>
                    </li>
                    <li style={{padding: '1% 0%'}}>
                        <strong style={{paddingRight: '1%'}}>Orelabrutinib:</strong> In February 2023, Biogen Inc. ("Biogen") notified the Company of its decision to terminate its licence and collaboration agreement with the Company, an oral small molecule Bruton's tyrosine kinase (“BTK”) inhibitor for the potential treatment of Multiple Sclerosis (“MS”) along with the research and development services. Innocare’s Orelabrutinib has completed Phase 2 trials, focusing on both relapsing and progressive forms of MS. Recent results have shown promise in reducing relapse rates in relapsing MS and mitigating disease progression in progressive forms.<sup><a style={{textDecoration: 'underline'}} href="https://www.innocarepharma.com/uploads/2024-05-07/2023-Annual-Report-EN.pdf" target="_blank">17,18</a></sup>
                    </li>
                    <li style={{padding: '1% 0%'}}>
                        <strong style={{paddingRight: '1%'}}>Remibrutinib:</strong>Novartis’s Remibrutinib is in Phase 3 development. The upcoming milestones for Remibrutinib involve further data collection on its efficacy and safety, with a focus on understanding its impact on disability progression and potential approval discussions with regulatory bodies.<sup><a style={{textDecoration: 'underline'}} href="https://clinicaltrials.gov/study/NCT05156281?tab=table" target="_blank">19</a></sup>
                    </li>
                    <li style={{padding: '1% 0%'}}>
                        <strong style={{paddingRight: '1%'}}>Tolebrutinib:</strong>Tolebrutinib, developed by Sanofi, is in Phase 3 trials. Recent data highlights its ineffectiveness in GEMINI studies 1 and 2, in reducing relapse rates and lesion burden in relapsing MS however, in the HERCULES study, Tolebrutinib achieved the primary goal of delaying the onset of confirmed disability progression in individuals with non-relapsing secondary progressive multiple sclerosis (nrSPMS), a group that currently lacks approved treatments and faces significant unmet medical needs. The next steps include the completion of ongoing studies and preparation for potential regulatory submissions, as well as exploring its effects in combination therapies and different MS subtypes. Phase 3 study results will form the basis for future discussions with global regulatory authorities.<sup><a style={{textDecoration: 'underline'}} href="https://www.sanofi.com/assets/dotcom/content-app/events/investor-presentation/2022/ACTRIMS-Investor-Call-2022/2022_02_25_Sanofi_ACTRIMS_Investor_Call.pdf" target="_blank">20,21</a></sup>
                    </li>
                </ul>
            </div>
            <div className="sub-section">
                <h4>Development timeline of BTK inhibitors in MS:</h4>
                <div>
                {image1 ? (
                    <GatsbyImage image={image1} alt='Loading...' className='blog-image'/>
                    ) : (
                    <img src='/img/Article_Image_1_v2.png' alt='Loading...' className='blog-image'/>
                )}
                </div>
                <p>Source: Clinicaltrials.gov, European Clinical Trial Registry, Company Press Releases, Company Presentations</p>
            </div>
            <div className="sub-section">
                <h4>Future Directions and Challenges</h4>
                <p>The development of BTK inhibitors for MS offers significant promise, but several challenges remain. Long-term safety, optimal dosing regimens, and potential off-target effects need to be thoroughly evaluated. Additionally, understanding how BTK inhibitors perform in different MS subtypes, such as primary progressive and secondary progressive MS, will be crucial for developing personalized treatment strategies.</p>
                <p>The development of BTK inhibitors has faced significant challenges due to severe adverse events. The FDA has placed clinical holds on four BTK inhibitors to date. In 2022, Tolebrutinib (Sanofi) and Orelabrutinib (Innocare) were put on hold, followed by Evobrutinib (Merck KGaA) and Fenebrutinib (Roche) in 2023. Elevated liver enzyme levels were the most common side effect observed. Notably, Fenebrutinib is a non-covalent molecule with irreversible bonding, which means it does not form a permanent bond with its target receptor. This characteristic might result in fewer side effects. The impact of these structural differences on safety will become clearer with the Phase 3 trial results.</p>
                <p>Ongoing research and clinical trials will provide more insights into the efficacy and safety of these BTK inhibitors. As our understanding of their mechanisms of action deepens, these therapies could play a pivotal role in the future management of MS.</p>
            </div>
            <div className="sub-section">
                <h4>Upcoming presentations of BTK inhibitors in ECTRIMS 2024</h4>
                <div style={{overflowX: (width > 768 ? 'unset': 'scroll')}}>
                    <table className="references-table">
                        <tr>
                            <th class="width-5" style={{color: 'white', textAlign: 'center'}}>Sr. No.</th>
                            <th class="width-5" style={{color: 'white', textAlign: 'center'}}>BTK Inhibitors</th>
                            <th class="width-45" style={{color: 'white', textAlign: 'center'}}>BTK Inhibitors Abstract Title</th>
                            <th class="width-5" style={{color: 'white', textAlign: 'center'}}>Presentation Date</th>
                            <th class="width-5" style={{color: 'white', textAlign: 'center'}}>Mode</th>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>1.</td>
                            <td>Evobrutinib</td>
                            <td>Targeting Inflammation: Evobrutinib’s Role in Controlling CNS Autoimmune Disease Through B Cell and Myeloid Cell Modulation</td>
                            <td>18 Sep. 2024, Wednesday</td>
                            <td>Poster (<a href="https://apps.congrex.com/ectrims2024/en-GB/pag/presentation/368916" target="_blank">P896</a>)</td>
                        </tr>
                        <tr >
                            <td rowspan="3">2.</td>
                            <td rowspan="3">Fenebrutinib</td>
                            <td>Exposure-response Analysis of Fenebrutinib in Patients with Relapsing Multiple Sclerosis</td>
                            <td>18 Sep. 2024, Wednesday</td>
                            <td>Poster (<a href="https://apps.congrex.com/ectrims2024/en-GB/pag/presentation/366714" target="_blank">P1611</a>)</td>
                        </tr>
                        <tr>
                            <td>Fenebrutinib Maintains Low Disease Activity in Relapsing Multiple Sclerosis: Results from the FENopta Trial Open-label Extension</td>
                            <td>18 Sep. 2024, Wednesday</td>
                            <td>Poster (<a href="https://apps.congrex.com/ectrims2024/en-GB/pag/presentation/366715" target="_blank">P1612</a>)</td>
                        </tr>
                        <tr>
                            <td>Elucidating the potential of Fenebrutinib, a noncovalent and reversible Bruton’s tyrosine kinase inhibitor, to modulate microglial inflammation in human brain cell systems</td>
                            <td>18 Sep. 2024, Wednesday</td>
                            <td>Poster (<a href="https://apps.congrex.com/ectrims2024/en-GB/pag/presentation/366712" target="_blank">P795</a>)</td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>3.</td>
                            <td>Remibrutinib</td>
                            <td>Remibrutinib Exposure in Cerebrospinal Fluid: Insights from a Study in Healthy Subjects</td>
                            <td>Not available</td>
                            <td>Poster (<a href="https://apps.congrex.com/ectrims2024/en-GB/pag/presentation/367314" target="_blank">P1319</a>)</td>
                        </tr>
                        <tr>
                            <td rowspan="2">4.</td>
                            <td rowspan="2">Tolebrutinib</td>
                            <td>Efficacy and Safety of Tolebrutinib Versus Teriflunomide in Relapsing Multiple Sclerosis: Results from the Phase 3 GEMINI 1 and 2 Trials</td>
                            <td>20 Sep. 2024, Friday</td>
                            <td>20 Sep. 2024, Friday Oral Session (<a href="https://apps.congrex.com/ectrims2024/en-GB/pag/presentation/368856" target="_blank">O135</a>)</td>
                        </tr>
                        <tr>
                            <td>Efficacy and Safety of Tolebrutinib Versus Placebo in Non-Relapsing Secondary Progressive Multiple Sclerosis: Results from the Phase 3 HERCULES Trial</td>
                            <td>20 Sep. 2024, Friday</td>
                            <td>Oral Session (<a href="https://apps.congrex.com/ectrims2024/en-GB/pag/presentation/368857" target="_blank">O136</a>)</td>
                        </tr>
                    </table>
                </div>
                <p>Source <strong><a style={{textDecoration: 'underline', color: 'black'}} href="https://apps.congrex.com/ectrims2024/en-GB/pag/" target="_blank">ECTRIMS 2024</a></strong></p>
            </div>
            <div className="sub-section">
                <h4>Conclusion</h4>
                <p>Bruton's tyrosine kinase is a key regulator of immune responses involved in multiple sclerosis. The development of BTK inhibitors, such as BIIB091, Fenebrutinib, Orelabrutinib, Remibrutinib, and Tolebrutinib, represents a promising advance in MS treatment. Recent setbacks have been observed with Evobrutinib and Tolebrutinib in RMS. However, Tolebrutinib's Phase 3 data from the HERCULES trial showed positive results in non-relapsing secondary progressive multiple sclerosis. Additionally, Fenebrutinib's Phase 2 OLE data from the FENopta study indicated near-complete suppression of disease activity and disability progression for up to 48 weeks in patients with relapsing multiple sclerosis. These drugs offer the potential to significantly alter the course of the disease by targeting underlying immune mechanisms. As research progresses, these therapies may provide new and effective options for managing MS, improving patient outcomes, and enhancing the quality of life for those affected by this challenging condition.</p>
            </div>
            <div className="sub-section">
                <h4>References</h4>
                <div style={{overflowX: (width > 768 ? 'unset': 'scroll')}}>
                    <table className="references-table">
                        <tr>
                            <th class="width-6" style={{color: 'white', textAlign: 'center'}}>Sr. No.</th>
                            <th class="width-44" style={{color: 'white', textAlign: 'center'}}>References</th>
                            <th class="width-6" style={{color: 'white', textAlign: 'center'}}>Sr. No.</th>
                            <th class="width-44" style={{color: 'white', textAlign: 'center'}}>References</th>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>1.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.mayoclinic.org/diseases-conditions/multiple-sclerosis/symptoms-causes/syc-20350269" target="_blank">Mayo Clinic</a></td>
                            <td>13.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.neurologylive.com/view/neurovoices-diana-gallagher-new-phase-2-fusion-study-btk-inhibitor-biib091" target="_blank">Diana Gallagher- Biogen MD.2023</a></td>
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC11025433/" target="_blank">Laura Airas et al, Ther Adv Neurol Disord. 2024</a></td>
                            <td>14.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8204096/#:~:text=In%20vitro%2C%20BIIB091%20potently%20inhibited%20BTK%E2%80%90dependent%20proximal%20signaling,efficacy%20of%20B%20cell%E2%80%90targeted%20therapeutics%20in%20multiple%20sclerosis." target="_blank">Eris Bame et al, Clin Transl Immunology. 2021</a></td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>3.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9315179/" target="_blank">Dalia L Rotstein, Mult Scler. 2022</a></td>
                            <td>15.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.merckgroup.com/en/news/evobrutinib-phase-lll.html" target="_blank">Merck PR-Dec 2024</a></td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td><a style={{textDecoration: 'underline'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9315179/" target="_blank">Avinash Kolli et al, Practical Neurology, 2023</a></td>
                            <td>16.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.gene.com/media/statements/ps_113023" target="_blank">Roche PR-Nov 2023</a></td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>5.</td>
                            <td>Tolebrutinib - <a style={{color: 'black', textDecoration: 'underline'}} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8434816/" target="_blank">Prof. Daniel S Reich, et al. Lancet Neurol. 2021 Sep</a></td>
                            <td>17.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.innocarepharma.com/en/development/Orelabrutinib" target="_blank">Innocar Orelabrutinib</a></td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>Fenebrutinib – <a style={{color: 'black', textDecoration: 'underline'}} href="https://www.neurology.org/doi/10.1212/WNL.0000000000205299" target="_blank">Bar-Or Amit et al, Neurology 2024</a></td>
                            <td>18.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.innocarepharma.com/uploads/2024-05-07/2023-Annual-Report-EN.pdf" target="_blank">Innocare Annual Report-2023</a></td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>7.</td>
                            <td>Orelabrutinib – <a style={{color: 'black', textDecoration: 'underline'}} href="https://www.innocarepharma.com/uploads/2024-05-07/2023-Annual-Report-EN.pdf" target="_blank">Innocare Annual Report 2023</a></td>
                            <td>19.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://clinicaltrials.gov/study/NCT05156281?tab=table" target="_blank">Clinical Trial for Safety and efficacy of Remibrutinib</a></td>
                        </tr>
                        <tr>
                            <td>8.</td>
                            <td>Evobrutinib – <a style={{textDecoration: 'underline'}} href="https://pubmed.ncbi.nlm.nih.gov/38436271/#:~:text=Patients%20receiving%20DBP%20evobrutinib%2075,neurofilament%20light%20chain%20fell%20to" target="_blank">Montalban X et al, Multiple Sclerosis, 2024</a></td>
                            <td>20.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.sanofi.com/en/media-room/press-releases/2024/2024-09-02-05-00-00-2938875" target="_blank">Sanofi PR-Sep 2024</a></td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>9.</td>
                            <td>Tolebrutinib – <a style={{color: 'black', textDecoration: 'underline'}} href="https://www.sanofi.com/en/media-room/press-releases/2021/2021-10-13-07-00-00-2313117" target="_blank">Sanofi PR – Oct 2021</a></td>
                            <td>21.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.sanofi.com/assets/dotcom/content-app/events/investor-presentation/2022/ACTRIMS-Investor-Call-2022/2022_02_25_Sanofi_ACTRIMS_Investor_Call.pdf" target="_blank">Sanofi Presentation 2022</a></td>
                        </tr>
                        <tr>
                            <td>10.</td>
                            <td>Fenebrutinib – <a style={{color: 'black', textDecoration: 'underline'}} href="https://www.roche.com/media/releases/med-cor-2024-09-04" target="_blank">Roche PR – Sep 2024</a></td>
                            <td>22.</td>
                            <td><a style={{textDecoration: 'underline'}} href="https://www.who.int/news-room/fact-sheets/detail/multiple-sclerosis" target="_blank">WHO – MS Key Facts</a></td>
                        </tr>
                        <tr style={{backgroundColor: '#f2f2f2'}}>
                            <td>11.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://www.sanofi.com/en/media-room/press-releases/2024/2024-09-02-05-00-00-2938875" target="_blank">Sanofi PR – Sep 2024</a></td>
                            <td>23.</td>
                            <td><a style={{textDecoration: 'underline'}} href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2012/202992s000lbl.pdf" target="_blank">Aubagio US Label</a></td>
                        </tr>
                        <tr>
                        <td>12.</td>
                            <td><a style={{color: 'black', textDecoration: 'underline'}} href="https://investors.biogen.com/static-files/e5f1af5d-d095-4fac-b0c8-cd6f09c93471" target="_blank">Biogen SEC filings-2023</a></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
`;
